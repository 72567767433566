import request from "../utils/request";

export function getTangCa(params) {
  return request({
    url: "tangca",
    method: "get",
    params,
  });
}

export function xoaTangCa(data) {
  return request({
    url: "xoatangca",
    method: "put",
    data,
  });
}

export function xuLyTangCa(data) {
  return request({
    url: "xulytangca",
    method: "put",
    data,
  });
}

export function chiTietTangCa(params) {
  return request({
    url: "chitiettangca",
    method: "get",
    params,
  });
}

export function xuLyXacNhan(data) {
  return request({
    url: "xulyxacnhantangca",
    method: "put",
    data,
  });
}

export function addTangCa(data) {
  return request({
    url: "tangca-web",
    method: "post",
    data,
  });
}

export function editTangCa(id,data) {
  return request({
    url: `tangca-web/${id}`,
    method: "put",
    data,
  });
}

export function deleteTangCa(id) {
  return request({
    url: `xoatangca-web/${id}`,
    method: "delete",
  });
}
