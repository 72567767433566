<template>
  <v-container fluid>
    <v-row>
      <v-col sm="12" lg="5" md="5" xs="12">
        <v-card elevation="20">
          <div
            style="display: flex; justify-content: flex-end"
            class="pt-3 pr-3"
          >
            <v-btn @click="inDeNghiTangCa">
              <v-icon>mdi-printer</v-icon>
            </v-btn>
          </div>
          <v-layout style="color: black; font-weight: bold" class="pt-6 pb-6">
            <v-layout column align-center>
              <div>Công Ty TMDV & MT Ngôi Sao Xanh</div>
              <div>BM01</div>
            </v-layout>
            <v-spacer></v-spacer>
            <v-layout column align-center>
              <div>Cộng Hòa Xã Hội Chủ Nghĩa Việt Nam</div>
              <div>Độc lập - Tự do - Hạnh Phúc</div>
            </v-layout>
          </v-layout>
          <v-layout>
            <v-spacer></v-spacer>
            <div class="pr-4">
              Ngày lập phiếu: {{ formatDate(tangCa.created_at) }}
            </div>
          </v-layout>
          <br />

          <v-layout column align-center>
            <v-card-title>ĐỀ NGHỊ TĂNG CA</v-card-title>
            <v-card-subtitle>Kính gửi phòng sản xuất</v-card-subtitle>
          </v-layout>

          <v-card-text class="text--primary pl-8">
            <div class="tieude mb-6">
              Vì nhu cầu công việc, chúng tôi đề nghị tăng ca với nội dung như
              sau:
            </div>
            <div class="tieude">
              Lý do tăng ca: <b>{{ tangCa.ly_do_tang_ca }}</b>
            </div>
            <div class="tieude">
              Bộ phận đề nghị tăng ca:
              <b>{{ tangCa.bo_phan_de_nghi_tang_ca }}</b>
            </div>
            <div class="tieude">
              Số người tham gia: <b>{{ tangCa.so_nguoi_tham_gia }}</b>
            </div>
            <div class="tieude">
              Thời gian tăng ca dự kiến từ:
              <b>
                {{ formatDateTime(tangCa.bat_dau) }} -
                {{ formatDateTime(tangCa.ket_thuc) }}</b
              >
            </div>
            <br />
            <hr />
          </v-card-text>
          <v-layout class="pb-6">
            <v-layout column align-center>
              <div class="tieude-footer">NGƯỜI ĐỀ NGHỊ</div>
              <div>
                Tổ trưởng:
                <b>{{ tangCa.to_truong ? tangCa.to_truong.name : "" }}</b>
              </div>
            </v-layout>
            <v-layout column align-center>
              <div class="tieude-footer">TRẠNG THÁI</div>
              <div v-if="tangCa.trang_thai == 'CHO_DUYET'">Chờ phê duyệt</div>
              <v-layout v-else column align-center>
                <div v-if="tangCa.trang_thai == 'TU_CHOI'">TỪ CHỐI</div>
                <div v-if="tangCa.trang_thai == 'CHAP_NHAN'">CHẤP NHẬN</div>
                <div>
                  Bởi:
                  <b>{{
                    tangCa.nguoi_duyet_tang_ca
                      ? tangCa.nguoi_duyet_tang_ca.name
                      : ""
                  }}</b>
                </div>
              </v-layout>
            </v-layout>
            <v-layout column align-center>
              <div class="tieude-footer">HÀNH ĐỘNG</div>
              <v-btn
                tile
                color="success"
                small
                class="mb-2 mt-2"
                @click="duyetTangCa"
                v-if="tangCa.trang_thai != 'CHAP_NHAN'"
              >
                <v-icon left> mdi-check </v-icon>
                Phê duyệt
              </v-btn>
              <v-btn
                tile
                color="pink"
                dark
                small
                @click="tuChoiTangCa"
                v-if="tangCa.trang_thai != 'TU_CHOI'"
              >
                <v-icon left> mdi-close </v-icon>
                Từ chối
              </v-btn>
            </v-layout>
          </v-layout>
        </v-card>
      </v-col>
      <v-col sm="12" lg="7" md="7" xs="12">
        <v-card elevation="20">
          <div
            style="display: flex; justify-content: flex-end"
            class="pt-3 pr-3"
          >
            <v-btn
              @click="inXacNhanTangCa"
              :disabled="
                !(tangCa.cong_nhan && tangCa.cong_nhan.length > 0) ||
                  !tangCa.ket_qua_nghiem_thu_tang_ca
              "
            >
              <v-icon>mdi-printer</v-icon>
            </v-btn>
          </div>
          <v-layout style="color: black; font-weight: bold" class="pt-6 pb-6">
            <v-layout column align-center>
              <div>Công Ty TMDV & MT Ngôi Sao Xanh</div>
              <div>BM02</div>
            </v-layout>
            <v-spacer></v-spacer>
            <v-layout column align-center>
              <div>Cộng Hòa Xã Hội Chủ Nghĩa Việt Nam</div>
              <div>Độc lập - Tự do - Hạnh Phúc</div>
            </v-layout>
          </v-layout>
          <!-- <v-layout>
            <v-spacer></v-spacer>
            <div class="pr-4">Ngày lập phiếu: Ngày 01 tháng 01 năm 2021</div>
          </v-layout> -->
          <br />
          <v-layout column align-center>
            <v-card-title>XÁC NHẬN KẾT QUẢ TĂNG CA</v-card-title>
          </v-layout>
          <div
            v-if="
              (tangCa.cong_nhan && tangCa.cong_nhan.length > 0) ||
                tangCa.ket_qua_nghiem_thu_tang_ca
            "
          >
            <v-card-text class="text--primary pl-8">
              <div class="tieude">
                Kết quả nghiệm thu tăng ca:
                <b>{{ tangCa.ket_qua_nghiem_thu_tang_ca }}</b>
              </div>
              <div class="tieude">Số người tham gia tăng ca:</div>
              <br />
              <v-data-table
                :headers="headers"
                :items="tangCa.cong_nhan"
                hide-default-footer
              >
                <template v-slot:[`item.time`]="{ item }">
                  <v-layout
                    column
                    v-if="item.checking && item.checking.length > 0"
                  >
                    <div
                      v-for="(chamcong, index) in item.checking"
                      :key="index"
                      class="pb-4"
                    >
                      <b>{{ chamcong.ca.name }}</b> :
                      {{ formatTime(chamcong.time_check_in) }} đến
                      {{ formatTime(chamcong.time_check_out) }}
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="secondary darken-4"
                            v-bind="attrs"
                            v-on="on"
                            @click="viewChecking(chamcong)"
                          >
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>Chi tiết chấm công</span>
                      </v-tooltip>
                    </div>
                  </v-layout>
                </template>
                <template v-slot:[`item.bat_dau`]="{ item }">
                  {{ formatTime(item.bat_dau) }}
                </template>

                <template v-slot:[`item.name`]="{ item }">
                  <div v-if="item.user">{{ item.user.name }}</div>
                </template>
                <template v-slot:[`item.ket_thuc`]="{ item }">
                  {{ formatTime(item.ket_thuc) }}
                </template>
                <template v-slot:[`item.tong_gio`]="{ item }">
                  {{ tinhTongGio(item.bat_dau, item.ket_thuc) }}
                </template>
              </v-data-table>
              <hr />
            </v-card-text>
            <v-layout class="pb-6">
              <v-layout column align-center>
                <div class="tieude-footer">TỔ TRƯỞNG</div>
                <b>{{ tangCa.to_truong ? tangCa.to_truong.name : "" }}</b>
              </v-layout>
              <v-layout column align-center>
                <div class="tieude-footer">TRẠNG THÁI</div>
                <div v-if="tangCa.trang_thai_xac_nhan == 'CHO_DUYET'">
                  Chờ phê duyệt
                </div>
                <v-layout v-else column align-center>
                  <div v-if="tangCa.trang_thai_xac_nhan == 'TU_CHOI'">
                    TỪ CHỐI
                  </div>
                  <div v-if="tangCa.trang_thai_xac_nhan == 'CHAP_NHAN'">
                    CHẤP NHẬN
                  </div>
                  <div>
                    Bởi:
                    <b>{{
                      tangCa.nguoi_duyet_xac_nhan
                        ? tangCa.nguoi_duyet_xac_nhan.name
                        : ""
                    }}</b>
                  </div>
                </v-layout>
              </v-layout>
              <v-layout column align-center>
                <div class="tieude-footer">HÀNH ĐỘNG</div>
                <v-btn
                  tile
                  color="success"
                  small
                  class="mb-2 mt-2"
                  @click="pheDuyetXacNhan"
                  v-if="tangCa.trang_thai_xac_nhan != 'CHAP_NHAN'"
                >
                  <v-icon left> mdi-check </v-icon>
                  Phê duyệt
                </v-btn>
                <v-btn
                  tile
                  color="pink"
                  dark
                  small
                  @click="tuChoiXacNhan"
                  v-if="tangCa.trang_thai_xac_nhan != 'TU_CHOI'"
                >
                  <v-icon left> mdi-close </v-icon>
                  Từ chối
                </v-btn>
              </v-layout>
            </v-layout>
          </div>
          <v-layout
            v-else
            align-center
            justify-center
            column
            style="height: 400px"
          >
            <v-icon color="pink" size="70"> mdi-book</v-icon>
            <div>Chưa lập phiếu</div>
          </v-layout>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { chiTietTangCa } from "@/api/tangca";
import { xuLyTangCa, xuLyXacNhan } from "@/api/tangca";
import printContent from "./print.js";
export default {
  data: () => ({
    headers: [
      {
        text: "Họ tên",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "Giờ bắt đầu",
        align: "start",
        sortable: false,
        value: "bat_dau",
      },
      {
        text: "Giờ kết thúc",
        align: "start",
        sortable: false,
        value: "ket_thuc",
      },
      {
        text: "Tổng giờ",
        value: "tong_gio",
        sortable: false,
        align: "checking",
      },
      {
        text: "Ghi chú",
        value: "ghi_chu",
        sortable: false,
        align: "ghi_chu",
      },
    ],
    tangCa: {},
  }),
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let data = await chiTietTangCa({ id: this.$route.params.id });
      this.tangCa = data;
      console.log(data);
    },
    inDeNghiTangCa() {
      var data = printContent(this.tangCa);
      // var html = data.de_nghi_tang_ca;

      // var blob = new Blob(["\ufeff", html], {
      //   type: "application/msword",
      // });

      // // Specify link url
      // var url =
      //   "data:application/vnd.ms-word;charset=utf-8," +
      //   encodeURIComponent(html);

      // var downloadLink = document.createElement("a");
      // let filename = "De Nghi Tang Ca.doc";
      // document.body.appendChild(downloadLink);
      // if (navigator.msSaveOrOpenBlob) {
      //   navigator.msSaveOrOpenBlob(blob, filename);
      // } else {
      //   // Create a link to the file
      //   downloadLink.href = url;

      //   // Setting the file name
      //   downloadLink.download = filename;

      //   //triggering the function
      //   downloadLink.click();
      // }
      // document.body.removeChild(downloadLink);

      var mywindow = window.open("", "PRINT", "height=720,width=1200");

      mywindow.document.write(
        "<html><head><title>" + document.title + "</title>"
      );
      mywindow.document.write("</head><body >");
      mywindow.document.write(data.de_nghi_tang_ca);
      mywindow.document.write("</body></html>");

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();

      return true;
    },
    inXacNhanTangCa() {
      var data = printContent(this.tangCa);
      var mywindow = window.open("", "PRINT", "height=720,width=1200");

      mywindow.document.write(
        "<html><head><title>" + document.title + "</title>"
      );
      mywindow.document.write("</head><body >");
      mywindow.document.write(data.xac_nhan_tang_ca);
      mywindow.document.write("</body></html>");

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();

      return true;
    },
    tinhTongGio(timeStart, timeEnd) {
      try {
        const start = new Date(timeStart).getTime();
        const end = new Date(timeEnd).getTime();
        const diff = end - start;
        const seconds = Math.floor(diff / 1000);
        const hour = Math.floor(seconds / 3600);
        const minute = Math.floor((seconds % 3600) / 60);
        if (minute) {
          return hour + " giờ " + minute + " phút";
        }
        return hour + "giờ";
      } catch (error) {
        return "";
      }
    },
    formatDateTime(e) {
      if (!e) return null;
      try {
        let date = new Date(e);
        let gio =
          date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let phut =
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        let ngay = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let thang =
          Number(date.getMonth() + 1) < 10
            ? "0" + Number(date.getMonth() + 1)
            : Number(date.getMonth() + 1);
        return (
          gio +
          "h " +
          phut +
          " Ngày " +
          ngay +
          "/" +
          thang +
          "/" +
          date.getFullYear()
        );
      } catch (error) {
        return "";
      }
    },
    formatDate(e) {
      if (!e) return "";
      try {
        let date = new Date(e);
        let ngay =
          +date.getDate() > 10 ? +date.getDate() : "0" + date.getDate();
        let thang =
          +date.getMonth() + 1 > 10
            ? +date.getMonth() + 1
            : "0" + (+date.getMonth() + 1);
        let nam = date.getUTCFullYear();

        return ngay + "/" + thang + "/" + nam;
      } catch (error) {
        return "";
      }
    },
    async pheDuyetXacNhan() {
      try {
        await xuLyXacNhan({
          trang_thai: "CHAP_NHAN",
          tang_ca_id: this.$route.params.id,
        });
        this.getData();
      } catch (error) {
        console.log(error);
      }
    },
    async tuChoiXacNhan() {
      try {
        await xuLyXacNhan({
          trang_thai: "TU_CHOI",
          tang_ca_id: this.$route.params.id,
        });
        this.getData();
      } catch (error) {
        console.log(error);
      }
    },

    formatTime(e) {
      if (!e) return null;
      try {
        let date = new Date(e);
        let gio =
          date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let phut =
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        return gio + "h " + phut;
      } catch (error) {
        return "";
      }
    },
    async duyetTangCa() {
      try {
        await xuLyTangCa({
          trang_thai: "CHAP_NHAN",
          tang_ca_id: this.$route.params.id,
        });
        this.getData();
      } catch (error) {
        console.log(error);
      }
    },
    async tuChoiTangCa() {
      try {
        await xuLyTangCa({
          trang_thai: "TU_CHOI",
          tang_ca_id: this.$route.params.id,
        });
        this.getData();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.tieude {
  color: black;
  font-size: 16px;
  margin-bottom: 10px;
}
.tieude-footer {
  color: black;
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: bold;
}
</style>
