const styles = `
<style>
@page { size: auto;  margin: 0mm; } 
table {
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid black; 
    text-align: left;
    padding: 8px;
  }
  
    .c-flex{
        display: flex;
        flex-direction: column
    }
    .d-flex {
        display: flex;
        flex-direction: row
    }
    .item-center{
        align-items: center
    }
    .justify-center {
        justify-content: center
    }
    .justify-between {
        justify-content: space-between
    }
    .bold {
        font-weight: bold
    }
</style>
`;

export default function htmlContent(data) {
  let deNghiTangCa = `
  ${styles}

  <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>
  <div style="padding: 60px">
        <div class='d-flex justify-between'>
            <div class="c-flex item-center justify-center bold">
                <div style="padding-bottom: 15px">Công Ty TMDV & MT Ngôi Sao Xanh</div>
                <div>BM01</div>
            </div>
            <div class="c-flex item-center justify-center bold">
                <div style="padding-bottom: 15px">Cộng hòa xã hội chủ nghĩa Việt Nam</div>
                <div>Độc lập - Tự do - Hạnh phuc</div>
            </div>
        </div>
        <div style="padding-top: 40px; float: right">Ngày lập phiếu: ${formatDate(
          data.created_at
        )}</div>
        <div style="padding-top: 100px; text-align: center" class="bold">ĐỀ NGHỊ TĂNG CA</div>
        <div style="padding-top: 10px; text-align: center">Kính gửi phòng sản xuất</div>
        <div class="c-flex" style="padding-top: 40px">
            <div style="padding-bottom: 20px">Vì nhu cầu công việc, chúng tôi đề nghị tăng ca với nội dung như sau:</div>
            <div style="padding-bottom: 10px">Lý do tăng ca: <b>${
              data.ly_do_tang_ca
            }</b></div>
            <div style="padding-bottom: 10px">Bộ phận đề nghị tăng ca: <b>${
              data.bo_phan_de_nghi_tang_ca
            }</b></div>
            <div style="padding-bottom: 10px">Số người tham gia: <b>${
              data.so_nguoi_tham_gia
            }</b></div>
            <div style="padding-bottom: 10px">Thời gian tăng ca dự kiến từ:               <b>
            ${formatDateTime(data.bat_dau)} -
            ${formatDateTime(data.ket_thuc)}</b
          ></div>
          <div style="padding-bottom: 10px">Địa điểm: <b>${
            data.dia_diem ? data.dia_diem.name : ""
          }</b></div>
        </div>
        <div style="border: 1px solid black; width: 100%; height: 110px; margin-top: 20px" class="d-flex">
            <div class='c-flex item-center' style="flex: 1; border-right: 1px solid black">
                <div class="bold" style="padding-top: 10px">NGƯỜI ĐỀ NGHỊ</div>   
                <div style="padding-top: 60px">Tổ trưởng: ${
                  data.to_truong ? data.to_truong.name : ""
                }</div>    
            </div>
            <div class='c-flex item-center' style="flex: 1; border-right: 1px solid black">
                <div class="bold" style="padding-top: 10px">KIỂM TRA/XÁC NHẬN</div>       
            </div>
            <div class='c-flex item-center' style="flex: 1;">
                <div class="bold" style="padding-top: 10px">PHÊ DUYỆT</div>       
            </div>
        </div>
    </div>
    </body></html>
    `;

  let xacNhanTangCa = `
  ${styles}
  <div style="padding: 60px">
  <div class='d-flex justify-between'>
      <div class="c-flex item-center justify-center bold">
          <div style="padding-bottom: 15px">Công Ty TMDV & MT Ngôi Sao Xanh</div>
          <div>BM01</div>
      </div>
      <div class="c-flex item-center justify-center bold">
          <div style="padding-bottom: 15px">Cộng hòa xã hội chủ nghĩa Việt Nam</div>
          <div>Độc lập - Tự do - Hạnh phuc</div>
      </div>
  </div>
  <div style="padding-top: 80px; text-align: center" class="bold">XÁC NHẬN KẾT QUẢ TĂNG CA</div>
  <div class="c-flex" style="padding-top: 40px">
      <div style="padding-bottom: 10px">Kết quả nghiệm thu tăng ca: <b>${
        data.ket_qua_nghiem_thu_tang_ca
      }</b></div>
      <div style="padding-bottom: 10px">Những người tham gia tăng ca</div>
      <div style="width: 100%">
      ${
        data.cong_nhan && data.cong_nhan.length
          ? `<table>
            <tr>
                <th>Họ tên</th>
                <th>Giờ bắt đầu</th>
                <th>Giờ kết thúc</th>
                <th>Tổng giờ</th>
                <th>Ghi chú</th>
            </tr>
            ${data.cong_nhan.map(
              (el) => `
            <tr>
            <td>${el.user.name ? el.user.name  : ""}</td>
            <td>${el.bat_dau ? formatDateTime(el.bat_dau) : ""}</td>
            <td>${el.ket_thuc ? formatDateTime(el.ket_thuc) : ""}</td>
            <td>${tinhTongGio(el.bat_dau, el.ket_thuc)}</td>
            <td>${el.ghi_chu ? el.ghi_chu : ""}</td>
        </tr>
            `
            )}

        </table>`
          : ""
      }
      </div>   
  </div>
  <div style="width: 100%; height: 110px; padding-top: 20px" class="d-flex">
      <div class='c-flex item-center' style="flex: 1;">
          <div class="bold" style="padding-top: 10px">TỔ/NHÓM TRƯỞNG</div>   
          <div style="padding-top: 60px">Tổ trưởng: ${
            data.to_truong ? data.to_truong.name : ""
          }</div> 
      </div>
      <div class='c-flex item-center' style="flex: 1;">
          <div class="bold" style="padding-top: 10px">BỘ PHẬN KIỂM TRA</div>       
      </div>
      <div class='c-flex item-center' style="flex: 1;">
          <div class="bold" style="padding-top: 10px">XÁC NHẬN</div>       
      </div>
  </div>
</div>
</body></html>
  `;

  return {
    de_nghi_tang_ca: deNghiTangCa,
    xac_nhan_tang_ca: xacNhanTangCa,
  };
}

function formatDate(e) {
  if (!e) return "";
  try {
    let date = new Date(e);
    let ngay = +date.getDate() > 10 ? +date.getDate() : "0" + date.getDate();
    let thang =
      +date.getMonth() + 1 > 10
        ? +date.getMonth() + 1
        : "0" + (+date.getMonth() + 1);
    let nam = date.getUTCFullYear();

    return ngay + "/" + thang + "/" + nam;
  } catch (error) {
    return "";
  }
}
function formatDateTime(e) {
  if (!e) return null;
  try {
    let date = new Date(e);
    let gio = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    let phut =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    let ngay = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let thang =
      Number(date.getMonth() + 1) < 10
        ? "0" + Number(date.getMonth() + 1)
        : Number(date.getMonth() + 1);
    return (
      gio +
      "h " +
      phut +
      " Ngày " +
      ngay +
      "/" +
      thang +
      "/" +
      date.getFullYear()
    );
  } catch (error) {
    return "";
  }
}

function tinhTongGio(timeStart, timeEnd) {
    try {
      const start = new Date(timeStart).getTime();
      const end = new Date(timeEnd).getTime();
      const diff = end - start;
      const seconds = Math.floor(diff / 1000);
      const hour = Math.floor(seconds/3600);
      const minute =   Math.floor((seconds%3600)/60)
      if(minute){
        return hour+ ' giờ ' + minute + ' phút'
      }
      return hour + 'giờ'
    } catch (error) {
      return "";
    }
}
